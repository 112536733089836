import * as React from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';

export const IconRefillDrink = (props: IBaseProps) => (
  <svg width={36} height={36} fill="none" {...props}>
    <title>Refill Drink Icon</title>
    <path
      fill="#E31837"
      d="M21.739 24.944a.694.694 0 1 1 1.389 0 .694.694 0 0 1-1.39 0ZM14.795 24.196a.694.694 0 1 1 1.389 0 .694.694 0 0 1-1.389 0ZM23.662 18.855a.694.694 0 1 0-1.389 0 .694.694 0 0 0 1.389 0ZM12.659 26.332a.694.694 0 1 1 1.388 0 .694.694 0 0 1-1.388 0Z"
    />
    <path
      fill="#E31837"
      fillRule="evenodd"
      d="M12.659 18.498c0-1.003.813-2.115 1.816-2.115h1.709c1.003 0 1.816 1.112 1.816 2.115v1.051a1.816 1.816 0 0 1-1.816 1.816h-1.71a1.816 1.816 0 0 1-1.815-1.816v-1.051Zm1.709-.32c0-.442.63-.319 1.018-.11l.207.111c.388.21 1.018.305 1.018.746v.624c0 .44-.63.318-1.018.109l-.207-.111c-.388-.21-1.018-.305-1.018-.746v-.624Z"
      clipRule="evenodd"
    />
    <path
      fill="#E31837"
      fillRule="evenodd"
      d="M10.522 4.273V.748c0-.413.335-.748.748-.748h.641c.413 0 .748.335.748.748v3.525h16.344c.413 0 .748.335.748.748v3.845a.748.748 0 0 1-.748.748h-1.522l-2.472 25.71a.748.748 0 0 1-.745.676H11.736a.748.748 0 0 1-.745-.676L8.52 9.614H6.997a.748.748 0 0 1-.748-.748V5.021c0-.413.335-.748.748-.748h3.525Zm.32 0V2.457h1.496v1.816h-1.495Zm-.928 5.341h16.172l-.263 2.73a11.376 11.376 0 0 0-7.357-.056l-1.617.539a9.989 9.989 0 0 1-5.326.275l-1.301-.29-.308-3.198Zm.448 4.652 1.956 20.345h11.364l2.004-20.839-.116-.043a9.988 9.988 0 0 0-6.665-.124l-1.617.54c-1.956.652-4.053.76-6.066.312l-.86-.19Zm18-6.04H7.638V5.662h20.724v2.564Z"
      clipRule="evenodd"
    />
    <path
      fill="#E31837"
      fillRule="evenodd"
      d="M17.288 29.142c0-1.003.813-2.115 1.816-2.115h1.709c1.003 0 1.816 1.112 1.816 2.115v1.051a1.816 1.816 0 0 1-1.816 1.816h-1.71a1.816 1.816 0 0 1-1.815-1.816v-1.051Zm1.709-.32c0-.441.63-.319 1.018-.11l.207.111c.388.21 1.018.305 1.018.746v.624c0 .44-.63.318-1.018.11l-.207-.112c-.388-.209-1.018-.305-1.018-.746v-.624Z"
      clipRule="evenodd"
    />
  </svg>
);

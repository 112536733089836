import styled from 'styled-components';

import {
  TextContainer as StyledTextContainer,
  TileImage as StyledTileImage,
  TileTitle as StyledTileTitle,
} from './marketing-tile-list.styled.default';

export * from './marketing-tile-list.styled.default';

export const StyledTileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border-radius: 0.563rem;
  background-color: rgb(255, 255, 255, 0.5);
  margin-block-end: 1rem;
  ${Styles.desktop} {
    height: 7rem;
  }
`;

export const TileImage = styled(StyledTileImage)`
  max-height: 3.75em;
  max-width: 3.75rem;
  margin: 0;
`;

export const TextContainer = styled(StyledTextContainer)`
  p {
    color: ${p => p.theme.token('text-link-default')};
  }
`;

export const TileTitle = styled(StyledTileTitle)`
  color: ${p => p.theme.token('text-link-default')};
  margin-block-start: 0;
  margin-block-end: 4px;
  margin-inline: 0;
  font-weight: 700;
`;

import { ActionButtonVariants } from 'components/action-button';

import { themeBase } from './theme-base';

export const theme = {
  ...themeBase,
  pickup: {
    ...themeBase.pickup,
    icon: 'restaurant',
  },
  catering: {
    ...themeBase.catering,
    pickup: {
      ...themeBase.catering.pickup,
      icon: 'cateringTwo',
    },
  },
  iconContainer: {
    ...themeBase.iconContainer,
  },
  text: {
    ...themeBase.text,
  },
  bottomServiceWrapper: {
    ...themeBase.bottomServiceWrapper,
  },
  underline: {
    ...themeBase.underline,
  },
  inProgressVariant: ActionButtonVariants.INVERSE,
} as const;
